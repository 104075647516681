export const responsiveSwitch = (...inputs) => {
  const options = inputs.length > 3 ? inputs[0] : {
    xs: null,
    s: null,
    m: null,
    l: null,
    xl: null
  }
  const config = inputs.length > 4 ? inputs[1] : {
    xs: "(max-width: 400px)",
    s: "(min-width: 401px) and (max-width: 768px)",
    m: "(min-width: 769px) and (max-width: 1024px)",
    l: "(min-width: 1025px) and (max-width: 1200px)",
    xl: "(min-width: 1201px)"
  }

  const matched_options = []
  Object.entries(config).forEach(([size, query]) => {
    if (window.matchMedia(query).matches){
      matched_options.push(options[size])
    }
  })

  // console.log("responsiveSwitch: ", options, config, matched_options);
  return matched_options;
}