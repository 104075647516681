import React, { useEffect, useState } from 'react';
// import {
//   LineChart, Line, AreaChart, Area, BarChart, Bar,
//   ScatterChart, Scatter, ComposedChart, PieChart, Pie, RadarChart,
//   Radar, RadialBarChart, RadialBar, XAxis, YAxis, ZAxis, CartesianGrid,
//   PolarGrid, PolarAngleAxis, PolarRadiusAxis, Tooltip, Legend, Brush,
//   ResponsiveContainer, Label, ReferenceLine, Cell, ReferenceArea
// } from 'recharts';
import styled from 'styled-components';

let recharts;
let ResponsiveContainer;
let LineChart, Line, AreaChart, Area, BarChart, Bar, ScatterChart, 
  Scatter, ComposedChart, PieChart, Pie, RadarChart,
  Radar, RadialBarChart, RadialBar, XAxis, YAxis, ZAxis, CartesianGrid,
  PolarGrid, PolarAngleAxis, PolarRadiusAxis, Tooltip, Legend, Brush,
  Label, ReferenceLine, Cell, ReferenceArea

export const UnstyledChart = (props) => {
  // required
  const {
    type, width, height,
    margin, data, onClick, onMouseDown, onMouseMove,
    stackOffset, innerRadius, outerRadius, configuration, } = props;
  // optional with default
  const style = props.style ? props.style : {};

  let getChart;

  let getLabelsXAxis;

  let getLabelsYAxis;

  let getElements;

  const [renderCount, setRenderCount] = useState(0);
  
  if (typeof recharts == 'undefined') {
    recharts = import("recharts").then(data=>{
      recharts = data;
              
      ({
          LineChart, Line, AreaChart, Area, BarChart, Bar,
          ScatterChart, Scatter, ComposedChart, PieChart, Pie, RadarChart,
          Radar, RadialBarChart, RadialBar, XAxis, YAxis, ZAxis, CartesianGrid,
          PolarGrid, PolarAngleAxis, PolarRadiusAxis, Tooltip, Legend, Brush,
          ResponsiveContainer, Label, ReferenceLine, Cell, ReferenceArea
        } = recharts)
  
  
        setRenderCount(1)
        // console.log("recharts", data, recharts.ResponsiveContainer, recharts, ResponsiveContainer, renderCount)
    })
  }else{
    // Ensure recharts is treated as a promise
    Promise.resolve(recharts).then(data => {
      setRenderCount(1)
    }).catch(error => {
      console.error('Failed to load recharts:');
    });
  }


  // console.log("recharts2", data, recharts.ResponsiveContainer, recharts, ResponsiveContainer, renderCount)


  if ( recharts.ResponsiveContainer != null ) {
    getChart = (children) => {
      switch (type) {
        case "line":
          return (<LineChart width={width} height={height} margin={margin} data={data} onClick={onClick} onMouseDown={onMouseDown} onMouseMove={onMouseMove} stackOffset={stackOffset} style={{ ...style }}>{children}</LineChart>)
  
        case "area":
          return (<AreaChart width={width} height={height} margin={margin} data={data} onClick={onClick} stackOffset={stackOffset} style={{ ...style }}>{children}</AreaChart>)
  
        case "bar":
          return (<BarChart width={width} height={height} margin={margin} data={data} onClick={onClick} stackOffset={stackOffset} style={{ ...style }}>{children}</BarChart>)
  
        case "scatter":
          return (<ScatterChart width={width} height={height} margin={margin} data={data} onClick={onClick} stackOffset={stackOffset} style={{ ...style }}>{children}</ScatterChart>)
  
        case "composed":
          return (<ComposedChart width={width} height={height} margin={margin} data={data} onClick={onClick} onMouseDown={onMouseDown} stackOffset={stackOffset} style={{ ...style }}>{children}</ComposedChart>)
  
        case "pie":
          return (<PieChart width={width} height={height} margin={margin} data={data} onClick={onClick} stackOffset={stackOffset} style={{ ...style }}>{children}</PieChart>)
  
        case "radar":
          return (<RadarChart width={width} height={height} margin={margin} data={data} onClick={onClick} stackOffset={stackOffset} style={{ ...style }}>{children}</RadarChart>)
  
        case "radial":
          return (<RadialBarChart width={width} height={height} margin={margin} data={data} innerRadius={innerRadius} outerRadius={outerRadius} onClick={onClick} stackOffset={stackOffset} style={{ ...style }}>{children}</RadialBarChart>)
  
        default:
          return (<h3>selected chart type does not exist...</h3>)
      }
    }
  
    getLabelsXAxis = () => {
      const elements = [];
      let style = {};
      if (configuration.xAxis.labels && configuration.xAxis.labels.length > 0) {
        configuration.xAxis.labels.forEach(element => {
          style = element.style ? props.style : {};
          switch (element.elementType) {
            case "label":
              var { elementType, ...elementProps } = element;
              elements.push((<Label style={{ ...style }}  {...elementProps}></Label>));
              break;
          }
        })
      }
      return elements;
    }
  
    getLabelsYAxis = () => {
      const elements = [];
      let style = {};
      if (configuration.yAxis.labels && configuration.yAxis.labels.length > 0) {
        configuration.yAxis.labels.forEach(element => {
          style = element.style ? props.style : {};
          switch (element.elementType) {
            case "label":
              var { elementType, ...elementProps } = element;
              elements.push((<Label style={{ ...style }}  {...elementProps}></Label>));
              break;
          }
        })
      }
      return elements;
    }
  
    getElements = () => {
      const elements = [];
      if (typeof configuration !== "undefined"){
        if (configuration?.toolTip) {
          elements.push(<Tooltip {...configuration.toolTip}></Tooltip>);
        }
        configuration.elements.forEach(element => {
          switch (element.elementType) {
    
            case "line":
              var { elementType, ...elementProps } = element;
              elements.push((<Line {...elementProps}></Line>));
              break;
    
            case "area":
              var { elementType, ...elementProps } = element;
              elements.push((<Area {...elementProps}></Area>));
              break;
    
            case "bar":
              var { elementType, ...elementProps } = element;
              elements.push((<Bar {...elementProps}></Bar>));
              break;
    
            case "scatter":
              var { elementType, ...elementProps } = element;
              elements.push((<Scatter {...elementProps}></Scatter>));
              break;
    
            case "pie":
              var { elementType, ...elementProps } = element;
              if (element.data) {
                elements.push((<Pie data={element.data} {...elementProps}>{element.data.map((entry, index) => { <Cell key={`cell-${index}`} fill={entry.fill} /> })}</Pie>));
              } else {
                elements.push((<Pie data={data} {...elementProps}>{data.map((entry, index) => { <Cell key={`cell-${index}`} fill={entry.fill} /> })}</Pie>));
              }
              break;
    
            case "radar":
              var { elementType, ...elementProps } = element;
              elements.push((<Radar {...elementProps}></Radar>));
              break;
    
            case "radial":
              var { elementType, ...elementProps } = element;
              elements.push((<RadialBar {...elementProps}></RadialBar>));
              break;
    
            case "referenceline":
              var { elementType, ...elementProps } = element;
              elements.push((<ReferenceLine {...elementProps}></ReferenceLine>));
              break;
          }
        });
    
        if (configuration.cartesianGrid) {
          elements.push(<CartesianGrid {...configuration.cartesianGrid}></CartesianGrid>);
        }
    
        if (configuration.polarGrid) {
          elements.push(<PolarGrid {...configuration.polarGrid}></PolarGrid>);
        }
    
        if (configuration.polarAngleAxis) {
          elements.push(<PolarAngleAxis {...configuration.polarAngleAxis}></PolarAngleAxis>);
        }
    
        if (configuration.polarRadiusAxis) {
          elements.push(<PolarRadiusAxis {...configuration.polarRadiusAxis}></PolarRadiusAxis>);
        }
        if (configuration.xAxis) {
          elements.push(<XAxis {...configuration.xAxis}>{getLabelsXAxis()}</XAxis>);
        }
    
        if (configuration.yAxis) {
          elements.push(<YAxis {...configuration.yAxis}>{getLabelsYAxis()}</YAxis>);
        }
    
        if (configuration.yAxisSecondary) {
          elements.push(<YAxis {...configuration.yAxisSecondary}></YAxis>);
        }
    
        if (configuration.zAxis) {
          elements.push(<ZAxis {...configuration.zAxis}></ZAxis>);
        }
    
        if (configuration.legend) {
          elements.push(<Legend {...configuration.legend}></Legend>);
        }
    
        if (configuration.brush) {
          elements.push(<Brush {...configuration.brush}></Brush>);
        }
    
        if (configuration.referenceArea) {
          elements.push(<ReferenceArea {...configuration.referenceArea}></ReferenceArea>);
        }
      }
  
      return elements;
    }
    // console.log("getElements", getElements)
  }

  // useEffect(() => {
  //   if (typeof recharts === 'undefined') {
  //     recharts = import("recharts").then(data=>{
  //       recharts = data
                
  //       let {
  //           LineChart, Line, AreaChart, Area, BarChart, Bar,
  //           ScatterChart, Scatter, ComposedChart, PieChart, Pie, RadarChart,
  //           Radar, RadialBarChart, RadialBar, XAxis, YAxis, ZAxis, CartesianGrid,
  //           PolarGrid, PolarAngleAxis, PolarRadiusAxis, Tooltip, Legend, Brush,
  //           ResponsiveContainer, Label, ReferenceLine, Cell, ReferenceArea
  //         } = recharts
  
  
  //       console.log("recharts", data, recharts, ResponsiveContainer)
  
  //       getChart = (children) => {
  //         switch (type) {
  //           case "line":
  //             return (<LineChart width={width} height={height} margin={margin} data={data} onClick={onClick} onMouseDown={onMouseDown} onMouseMove={onMouseMove} stackOffset={stackOffset} style={{ ...style }}>{children}</LineChart>)
      
  //           case "area":
  //             return (<AreaChart width={width} height={height} margin={margin} data={data} onClick={onClick} stackOffset={stackOffset} style={{ ...style }}>{children}</AreaChart>)
      
  //           case "bar":
  //             return (<BarChart width={width} height={height} margin={margin} data={data} onClick={onClick} stackOffset={stackOffset} style={{ ...style }}>{children}</BarChart>)
      
  //           case "scatter":
  //             return (<ScatterChart width={width} height={height} margin={margin} data={data} onClick={onClick} stackOffset={stackOffset} style={{ ...style }}>{children}</ScatterChart>)
      
  //           case "composed":
  //             return (<ComposedChart width={width} height={height} margin={margin} data={data} onClick={onClick} onMouseDown={onMouseDown} stackOffset={stackOffset} style={{ ...style }}>{children}</ComposedChart>)
      
  //           case "pie":
  //             return (<PieChart width={width} height={height} margin={margin} data={data} onClick={onClick} stackOffset={stackOffset} style={{ ...style }}>{children}</PieChart>)
      
  //           case "radar":
  //             return (<RadarChart width={width} height={height} margin={margin} data={data} onClick={onClick} stackOffset={stackOffset} style={{ ...style }}>{children}</RadarChart>)
      
  //           case "radial":
  //             return (<RadialBarChart width={width} height={height} margin={margin} data={data} innerRadius={innerRadius} outerRadius={outerRadius} onClick={onClick} stackOffset={stackOffset} style={{ ...style }}>{children}</RadialBarChart>)
      
  //           default:
  //             return (<h3>selected chart type does not exist...</h3>)
  //         }
  //       }
      
  //       getLabelsXAxis = () => {
  //         const elements = [];
  //         let style = {};
  //         if (configuration.xAxis.labels && configuration.xAxis.labels.length > 0) {
  //           configuration.xAxis.labels.forEach(element => {
  //             style = element.style ? props.style : {};
  //             switch (element.elementType) {
  //               case "label":
  //                 var { elementType, ...elementProps } = element;
  //                 elements.push((<Label style={{ ...style }}  {...elementProps}></Label>));
  //                 break;
  //             }
  //           })
  //         }
  //         return elements;
  //       }
      
  //       getLabelsYAxis = () => {
  //         const elements = [];
  //         let style = {};
  //         if (configuration.yAxis.labels && configuration.yAxis.labels.length > 0) {
  //           configuration.yAxis.labels.forEach(element => {
  //             style = element.style ? props.style : {};
  //             switch (element.elementType) {
  //               case "label":
  //                 var { elementType, ...elementProps } = element;
  //                 elements.push((<Label style={{ ...style }}  {...elementProps}></Label>));
  //                 break;
  //             }
  //           })
  //         }
  //         return elements;
  //       }
      
  //       getElements = () => {
  //         const elements = [];
  //         if (configuration.toolTip) {
  //           elements.push(<Tooltip {...configuration.toolTip}></Tooltip>);
  //         }
  //         configuration.elements.forEach(element => {
  //           switch (element.elementType) {
      
  //             case "line":
  //               var { elementType, ...elementProps } = element;
  //               elements.push((<Line {...elementProps}></Line>));
  //               break;
      
  //             case "area":
  //               var { elementType, ...elementProps } = element;
  //               elements.push((<Area {...elementProps}></Area>));
  //               break;
      
  //             case "bar":
  //               var { elementType, ...elementProps } = element;
  //               elements.push((<Bar {...elementProps}></Bar>));
  //               break;
      
  //             case "scatter":
  //               var { elementType, ...elementProps } = element;
  //               elements.push((<Scatter data={data.map(data_point => data_point[element.key])} {...elementProps}></Scatter>));
  //               break;
      
  //             case "pie":
  //               var { elementType, ...elementProps } = element;
  //               if (element.data) {
  //                 elements.push((<Pie data={element.data} {...elementProps}>{element.data.map((entry, index) => { <Cell key={`cell-${index}`} fill={entry.fill} /> })}</Pie>));
  //               } else {
  //                 elements.push((<Pie data={data} {...elementProps}>{data.map((entry, index) => { <Cell key={`cell-${index}`} fill={entry.fill} /> })}</Pie>));
  //               }
  //               break;
      
  //             case "radar":
  //               var { elementType, ...elementProps } = element;
  //               elements.push((<Radar {...elementProps}></Radar>));
  //               break;
      
  //             case "radial":
  //               var { elementType, ...elementProps } = element;
  //               elements.push((<RadialBar {...elementProps}></RadialBar>));
  //               break;
      
  //             case "referenceline":
  //               var { elementType, ...elementProps } = element;
  //               elements.push((<ReferenceLine {...elementProps}></ReferenceLine>));
  //               break;
  //           }
  //         });
      
  //         if (configuration.cartesianGrid) {
  //           elements.push(<CartesianGrid {...configuration.cartesianGrid}></CartesianGrid>);
  //         }
      
  //         if (configuration.polarGrid) {
  //           elements.push(<PolarGrid {...configuration.polarGrid}></PolarGrid>);
  //         }
      
  //         if (configuration.polarAngleAxis) {
  //           elements.push(<PolarAngleAxis {...configuration.polarAngleAxis}></PolarAngleAxis>);
  //         }
      
  //         if (configuration.polarRadiusAxis) {
  //           elements.push(<PolarRadiusAxis {...configuration.polarRadiusAxis}></PolarRadiusAxis>);
  //         }
  //         if (configuration.xAxis) {
  //           elements.push(<XAxis {...configuration.xAxis}>{getLabelsXAxis()}</XAxis>);
  //         }
      
  //         if (configuration.yAxis) {
  //           elements.push(<YAxis {...configuration.yAxis}>{getLabelsYAxis()}</YAxis>);
  //         }
      
  //         if (configuration.yAxisSecondary) {
  //           elements.push(<YAxis {...configuration.yAxisSecondary}></YAxis>);
  //         }
      
  //         if (configuration.zAxis) {
  //           elements.push(<ZAxis {...configuration.zAxis}></ZAxis>);
  //         }
      
  //         if (configuration.legend) {
  //           elements.push(<Legend {...configuration.legend}></Legend>);
  //         }
      
  //         if (configuration.brush) {
  //           elements.push(<Brush {...configuration.brush}></Brush>);
  //         }
      
  //         if (configuration.referenceArea) {
  //           elements.push(<ReferenceArea {...configuration.referenceArea}></ReferenceArea>);
  //         }
      
  //         return elements;
  //       }

  //       setRenderCount(1)
  //     })
  //   }

  //   return () => {
  //   };
  // }, []);

  // console.log("renderCount", renderCount, getElements, recharts);
  return recharts.ResponsiveContainer == null ? (<div class="loading-recharts"></div>) : (
    <ResponsiveContainer width="100%" height="100%">
      {getChart(getElements())}
    </ResponsiveContainer>
  );
}

const Chart = styled(UnstyledChart)`
${props => props.cssString}
`

export default Chart;