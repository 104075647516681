// import { concat, Series, DataFrame, toJSON } from "danfojs"
import { isArray, isPlainObject } from "./utils"

let danfojs = null

const import_danfojs = () => {
  // while( !danfojs || (typeof danfojs === 'object' && typeof danfojs.then === 'function') ) {
  // }
  if (danfojs) {

  } else {
    danfojs = import("danfojs").then(data=>{
      danfojs = data
      // console.log("danfojs", data, danfojs)
    })
  }
  return true
}

export const is_1D = (...inputs) => {
  const data = inputs[0];

  let result = true;
  if (isArray(data)) {
    data.forEach((entry) => {
      if (isArray(entry) || isPlainObject(entry)) {
        result = false;
        return result;
      }
    })
  } else {
    if ( isPlainObject(date) ) {
      Object.values(data).forEach((entry) => {
        if (isArray(entry) || isPlainObject(entry)) {
          result = false;
          return result;
        }
      })
    }
  }
  return result;
}

export const concatData = (...inputs) => {
  const data_list = inputs[0];
  const axis = inputs[1];
  const as_values = inputs[2];

  import_danfojs()
  // console.log("concat: ", data_list, axis, as_values)
  const df_list = []
  data_list.forEach((data) => {
    if ( data.length > 0 ) {
      let df = is_1D(data) ? new danfojs.Series([data]) : new danfojs.DataFrame(data)
      df_list.push(df)
    }
  })
  // df.print()
  let results = danfojs.concat({ dfList: df_list, axis: axis })
  // results.print()
  const output = as_values ? results.values : danfojs.toJSON(results)
  return output
}

export const applyMap = (...inputs) => {
  const data = inputs[0];
  const map_func = inputs[1];
  const as_values = inputs[2];
  import_danfojs()

 //console.log("applyMap: ", data, map_func, as_values)
  let df = is_1D(data) ? new danfojs.DataFrame([data]) : new danfojs.DataFrame(data)
  // df.print()
  let results = df.applyMap(map_func)
  // results.print()
  const output = as_values ? results.values : danfojs.toJSON(results)
  return output
}

export const apply = (...inputs) => {
  const data = inputs[0];
  const map_func = inputs[1];
  const as_values = inputs[2];
  import_danfojs()

  let df = new danfojs.DataFrame(data)
  // df.print()
  let results = df.apply(map_func)
  // results.print()
  return as_values ? results.values : danfojs.toJSON(results)
}

export const query = (...inputs) => {
  const data = inputs[0];
  const queries = inputs[1];
  const as_values = inputs[2];
  import_danfojs()

  let df = new danfojs.DataFrame(data)
  // df.print()
  try {
    let current_mask = null
  queries.forEach((query, i_q) => {
    if (i_q == 0) {
      current_mask = df[query[0]]
    }
    switch(query[1]) { // df["B"].gt(5).and(df["C"].lt(40))
      case 'lt':
        if (i_q == 0) {
          current_mask = current_mask.lt(query[2])
        } else {
          current_mask = current_mask.and(df[query[0]].lt(query[2]))
        }
        break;
      case 'gt':
        if (i_q == 0) {
          current_mask = current_mask.gt(query[2])
        } else {
          current_mask = current_mask.and(df[query[0]].gt(query[2]))
        }
        break;
      case 'le':
        if (i_q == 0) {
          current_mask = current_mask.le(query[2])
        } else {
          current_mask = current_mask.and(df[query[0]].le(query[2]))
        }
        break;
      case 'ge':
        if (i_q == 0) {
          current_mask = current_mask.ge(query[2])
        } else {
          current_mask = current_mask.and(df[query[0]].ge(query[2]))
        }
        break;
      case 'ne':
        if (i_q == 0) {
          current_mask = current_mask.ne(query[2])
        } else {
          current_mask = current_mask.and(df[query[0]].ne(query[2]))
        }
        break;
      case 'eq':
        if (i_q == 0) {
          current_mask = current_mask.eq(query[2])
        } else {
          current_mask = current_mask.and(df[query[0]].eq(query[2]))
        }
        break;
    }
  });
  let results = df.query(current_mask)
  // results.print()
  
  const results_shape = results.shape
  if(results_shape[0] == 0) {
    const empty_row = {}

    df.columns.forEach((column) => {
      empty_row[column] = undefined
    })

    const no_results = [empty_row]

    results = new danfojs.DataFrame(no_results)
  }

  return as_values ? results.values : danfojs.toJSON(results)

  } catch (error) {
    
    // console.log("query: ", error.message, error.stack)
    const empty_row = {}

    df.columns.forEach((column) => {
      empty_row[column] = undefined
    })

    const no_results = [empty_row]

    let results = new danfojs.DataFrame(no_results)

    return as_values ? results.values : danfojs.toJSON(results)
  }
}

export const max = (...inputs) => {
  const data = inputs[0];
  const axis = inputs[1];
  const as_values = inputs[2];
  import_danfojs()

  let df = new danfojs.DataFrame(data)
  // df.print()
  try {
    let results = df.max({axis: axis})

    return as_values ? results.values : danfojs.toJSON(results)

  } catch (error){
    // console.log("max: ", error.message, error.stack)
    const empty_row = {}

    df.columns.forEach((column) => {
      empty_row[column] = undefined
    })

    const no_results = [empty_row]

    let results = new danfojs.DataFrame(no_results)

    return as_values ? results.values : danfojs.toJSON(results)
  }
  // results.print()
}

export const min = (...inputs) => {
  const data = inputs[0];
  const axis = inputs[1];
  const as_values = inputs[2];
  import_danfojs()

  let df = new danfojs.DataFrame(data)
  // df.print()
  let results = df.min({axis: axis})
  // results.print()
  return as_values ? results.values : danfojs.toJSON(results)
}

export const mean = (...inputs) => {
  const data = inputs[0];
  const axis = inputs[1];
  const as_values = inputs[2];
  import_danfojs()

  let df = new danfojs.DataFrame(data)
  // df.print()
  let results = df.mean({axis: axis})
  // results.print()
  return as_values ? results.values : danfojs.toJSON(results)
}

export const median = (...inputs) => {
  const data = inputs[0];
  const axis = inputs[1];
  const as_values = inputs[2];
  import_danfojs()

  let df = new danfojs.DataFrame(data)
  // df.print()
  let results = df.median({axis: axis})
  // results.print()
  return as_values ? results.values : danfojs.toJSON(results)
}

export const mode = (...inputs) => {
  const data = inputs[0];
  const axis = inputs[1];
  const as_values = inputs[2];
  import_danfojs()

  let df = new danfojs.DataFrame(data)
  // df.print()
  let results = df.mode({axis: axis})
  // results.print()
  return as_values ? results.values : danfojs.toJSON(results)
}

export const round = (...inputs) => {
  const data = inputs[0];
  const decimal = inputs[1];
  const as_values = inputs[2];
  import_danfojs()

  let df = new danfojs.DataFrame(data)
  // df.print()
  let results = df.round(decimal)
  // results.print()
  return as_values ? results.values : danfojs.toJSON(results)
}

export const sum = (...inputs) => {
  const data = inputs[0];
  const axis = inputs[1];
  const as_values = inputs[2];
  import_danfojs()

  let df = new danfojs.DataFrame(data)
  // df.print()
  let results = df.sum({axis: axis})
  // results.print()
  return as_values ? results.values : danfojs.toJSON(results)
}

export const std = (...inputs) => {
  const data = inputs[0];
  const axis = inputs[1];
  const as_values = inputs[2];
  import_danfojs()

  let df = new danfojs.DataFrame(data)
  // df.print()
  let results = df.std({axis: axis})
  // results.print()
  return as_values ? results.values : danfojs.toJSON(results)
}

export const variance = (...inputs) => {
  const data = inputs[0];
  const axis = inputs[1];
  const as_values = inputs[2];
  import_danfojs()

  let df = new danfojs.DataFrame(data)
  // df.print()
  let results = df.var({axis: axis})
  // results.print()
  return as_values ? results.values : danfojs.toJSON(results)
}

export const describe = (...inputs) => {
  const data = inputs[0];
  import_danfojs()

  let df = new danfojs.DataFrame(data)
  // df.print()
  let results = df.describe()
  // results.print()
  return danfojs.toJSON(results)
}

export const iloc = (...inputs) => {
  const data = inputs[0];
  const iloc_config = inputs[1];
  const as_values = inputs[2];
  import_danfojs()

 //console.log("iloc: ", inputs)
  let df = new danfojs.DataFrame(data)
  // df.print()
  let results = df.iloc(iloc_config)
  // results.print()
  if (results.shape[1] == 1) {
    const output = as_values ? results[0].values : danfojs.toJSON(results)
   //console.log("iloc array: ", output)
    return output
  } else {
    const output = as_values ? results.values : danfojs.toJSON(results)
   //console.log("iloc array: ", output)
    return output
  }
}

