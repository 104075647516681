import React from "react"

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default function DateInput(props) {
  if (props.onChange == null) {
    props.onChange = (date) => {console.log('date: ', date)}
  }

  return <DatePicker {...props} onChange={(date)=>{props.onChange(date.toString())}}></DatePicker>;
}