export const math = (values, process) => {
  switch(process){
    case "+":
      return values.reduce((prev, curr) => {prev+curr}, 0);
    case "-":
      return values.reduce((prev, curr) =>{prev-curr}, values[0]);
    case "*":
      return values.reduce((prev, curr) =>{prev*curr}, values[0]);
    case "/":
      return values.reduce((prev, curr) =>{prev/curr}, values[0]);
  }
}

export const add = (...inputs) => {
  let results
  
  if(inputs.length > 4){
    results = inputs.slice(0, inputs.length-3).reduce((prev, curr) => {return prev+curr});
  }
  if(inputs.length == 4){
    results = inputs[0].reduce((prev, curr) => {return prev+curr});
  }
 //console.log('add: ', inputs, results)
  return results;
}

export const subtract = (...inputs) => {
  let results
  
  if(inputs.length > 4){
    results = inputs.slice(0, inputs.length-3).reduce((prev, curr) =>{return prev-curr});
  }
  if(inputs.length == 4){
    results = inputs[0].reduce((prev, curr) =>{return prev-curr});
  }
    //console.log('subtract: ', inputs, results)
  return results;
}

export const multiply = (...inputs) => {
  let results

  if(inputs.length > 4){
    results = inputs.slice(0, inputs.length-3).reduce((prev, curr) =>{return prev*curr});
  }
  if(inputs.length == 4){
    results = inputs[0].reduce((prev, curr) =>{return prev*curr});
  }
  //console.log('multiply: ', inputs, results)
  return results;
}

export const divide = (...inputs) => {
  let results

  if(inputs.length > 4){
    results = inputs.slice(0, inputs.length-3).reduce((prev, curr) =>{return prev/curr});
  }
  if(inputs.length == 4){
    results = inputs[0].reduce((prev, curr) =>{return prev/curr});
  }
  //console.log('divide: ', inputs, results)
  return results;
}

export const compare = (...inputs) => {
  const to_compare = inputs[0]
  const task = inputs[1]
  switch(task){
    case "<":
      return to_compare[0] < to_compare[1];
    case ">":
      return to_compare[0] > to_compare[1];
    case "<=":
      return to_compare[0] <= to_compare[1];
    case ">=":
      return to_compare[0] >= to_compare[1];
    case "==":
      return to_compare[0] == to_compare[1];
  }
}

export const round_number = (...inputs) => {
  const num = inputs[0]
  const decimals = inputs[1]
  let roundedNum = num.toFixed(decimals);
  if (decimals === 0) {
    roundedNum = parseInt(roundedNum);
  }
  return roundedNum;
}

export const floor_number = (...inputs) => {
  const num = inputs[0]
  return Math.floor(num);
}

export const ceiling_number = (...inputs) => {
  const num = inputs[0]
  return Math.floor(num)+1;
}

export const eval_equation = (...inputs) => {
  try {
    const expression = inputs[0]
    const scope = inputs.length >= 5 ? inputs[1] : {}
    const result_func = inputs.length >= 6 ? inputs[2] : (results) => {
      // console.log("The result of eval_equation is: ", results)
    }

    import('mathjs').then((data) => {
      const evaluate = data.evaluate
      const simplify = data.simplify
      const format = data.format

      if (!expression) throw new Error('Expression is required');

      result_func(Object.keys(scope).length > 0 ? evaluate(expression, scope) : format(simplify(expression)));
    });
  } catch (error) {
    console.error('Error evaluating expression', error);
    return null;
  }
};

export const formatNum = (...inputs) => {
  let number = inputs[0]
  let format = inputs[1]
  // Split the format into integer and decimal parts
  const [integerFormat, decimalFormat] = format.split(".");
  let [integerPart, decimalPart] = number.toString().split(".");

  // Round the number if there's a decimal format
  if (decimalFormat) {
    const decimalPlaces = decimalFormat.length;
    const factor = Math.pow(10, decimalPlaces);
    // Round the number to the specified number of decimal places
    number = Math.round(number * factor) / factor;
    // Update integer and decimal parts after rounding
    [integerPart, decimalPart] = number.toString().split(".");
  }

  // Pad the integer part
  integerPart = integerPart.padStart(integerFormat.length, '0');

  if (decimalFormat) {
    if (decimalPart) {
      // Truncate or pad the decimal part to match the format
      decimalPart = decimalPart.padEnd(decimalFormat.length, '0').substring(0, decimalFormat.length);
    } else {
      // If the number has no decimal part but the format requires it, pad with zeros
      decimalPart = "".padEnd(decimalFormat.length, '0');
    }
    return `${integerPart}.${decimalPart}`;
  } else {
    // If there's no decimal part in the format, return the integer part
    return integerPart;
  }
}

export const derive_equation = (...inputs) => {
  try {
    const expression = inputs[0]
    const derive_variable = inputs.length >= 5 ? inputs[1] : null
    const scope = inputs.length >= 6 ? inputs[2] : {}

    const result_func = inputs.length >= 7 ? inputs[3] : (results) => {
      // console.log("The result of derive_equation is: ", results)
    }

    if (scope == null){
      // console.log("variable to derive is required: ", expression)
      return null
    }

    import('mathjs').then((data) => {
      if (!expression) throw new Error('Expression is required');
      const result = data.derivative(expression, derive_variable);
      result_func(Object.keys(scope).length > 0 ? evaluate(result.toString(), scope) : result.toString())
    });

  } catch (error) {
    console.error('Error evaluating expression', error);
    return null;
  }
};