import React, { Lazy } from 'react';


export default function SvgPolyline(props) {
  
  return (
    <polyline {...props}>
    </polyline>
  );
}
