import React, { Lazy } from 'react';
import styled from 'styled-components';

function UnstyledFlex(props) {
  // console.log("flex-props: ", props)
  return (
    <div {...props} key={props.name} data-twlcName={props["data-twlcName"]} style={{...props.style, display: "flex", flexDirection: props.type}}>
    </div>
  );
}

const Flex = styled(UnstyledFlex)`
${props => props.cssString}
`
export default Flex;