export const do_if = (if_result, then_process, else_process, states, inputs, events) => {
  if ( if_result ) {
    if ( then_process ) {
      const result = then_process[1](...then_process[0])
      // console.log("do_if then: ", if_result, then_process, else_process, result, states, inputs, events)
      return result
    } else {
      return null
    }
  } else {
    if ( else_process ) {
      const result = else_process[1](...else_process[0])
      // console.log("do_if else: ", if_result, then_process, else_process, result, states, inputs, events)
      return result
    } else {
      return null
    }
  }
}

export const do_for = (amount, then_process) => {
  let results = [];
  for ( let i = 0; i < amount; i++ ) {
    results.push(then_process(i))
  }
  return results
}

export const do_foreach = (elements, then_process) => {
  let results = [];
  elements.forEach((element)=>{
    results.push(then_process(element))
  }); 
  return results
}

export const while_do = (if_process, then_process, state) => {
  while (if_process()) {
    then_process()
  }
}

export const and = (if_results, states) => {
  return if_results.reduce((accumulator, current) => !!accumulator && !!current);
}

export const or = (if_results, states) => {
  return if_results.reduce((accumulator, current) => !!accumulator || !!current);
}

export const not = (if_result, states) => {
  return !if_result;
}

export const equal = (results, states) => {
  let prev_res = results[0]
  let and_result = true
  results.forEach((current_result, i_res) => {
    if ( i_res ) {
      and_result = and_result && (current_result == prev_res);
      prev_res = current_result;
    }
  })
  return and_result;
}

export const do_switch = (...inputs) => {
  const func_input = inputs[0]
  const cases = inputs[1]
  const results = []
  cases.forEach(([test_func, do_func]) => {
    if (test_func(func_input)){
      results.push(do_func(func_input))
    }
  })
  return results
}

export const do_recursive = (...inputs) => {
  const funcArray = inputs[0] 
  const target = inputs[1]
  let route = inputs.length >= 6 ? inputs[2] : []
  const [objectFunc, arrayFunc, valueFunc] = funcArray;

  // Determine the type of the target and call the respective function
  if (Array.isArray(target)) {
    // Process with array function
    arrayFunc(target, route);

    // Recursively process each item in the array
    target.forEach((item, index) => {
      do_recursive(funcArray, item, [...route, index], {}, {}, {});
    });
  } else if (typeof target === 'object' && target !== null) {
    // Process with object function
    objectFunc(target, route);

    // Recursively process each property in the object
    Object.keys(target).forEach(key => {
      do_recursive(funcArray, target[key], [...route, key], {}, {}, {});
    });
  } else {
    // Process with value function
    valueFunc(target, route);
  }
}


export const run = (inputs_list, processes, states, inputs, events) => {
  let results = [];
 //console.log("RUN: ", processes, inputs_list)
  processes.forEach((process, i_proc) => {
    if ( process ) {
      if (inputs_list[i_proc]) {
        results.push(process(...inputs_list[i_proc]));
      } else {
        results.push(process());
      }
    } else {
      results.push(inputs_list[i_proc]);
    }
  })
 //console.log("RUN: ", processes, inputs_list, results)
  return results;
}

export const chain = (...inputs) => {
  const chain_inputs = inputs[0]
  const processes = inputs[1]
  const spread = inputs.length > 5 ? inputs[2] : true
 //console.log("chain:", chain_inputs, processes)
  let current_result = chain_inputs
  processes.forEach((process, i_proc) => {
    if (Array.isArray(current_result) && spread) {
      current_result = process(...current_result)
    } else {
      current_result = process(current_result)
    }
  })
  return current_result;
}