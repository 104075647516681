import React, { Lazy } from 'react';


export default function Button(props) {
  return (
      <button {...props}>
        {props.text}
      </button>
  );
}
