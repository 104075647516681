import React from "react";

function IframeComponent(props) {

  const { title = "Embedded Content", url, style = {border: "0"}, sandbox = true } = props

  if (!url) {
    return null;
  }

  return (
    <iframe
      src={url}
      style={style}
      sandbox={sandbox}
      title={title}
    ></iframe>
  );
}

export default IframeComponent;