import React, { Lazy } from 'react';


export default function SvgLine(props) {
  
  return (
    <line {...props}>
    </line>
  );
}
