import React from "react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

// WrapperRow component that handles index and style without passing them to the child
const WrapperRow = ({ index, style, styles, children }) => (
  <div className={index % 2 ? "virtual-list-odd" : "virtual-list-even"} style={{...style, ...styles[index%2]}}>
    {children}
  </div>
);

const VirtualList = (props) => {
  let {itemCount, itemSize, styles, children} = props
  itemCount = children.length
  
  return (
  <AutoSizer>
    {({ height, width }) => (
      <List className="List" height={height} itemCount={itemCount} itemSize={itemSize} width={width}>
        {({ index, style }) => (
          <WrapperRow index={index} style={style} styles={styles}>
            {children[index]}
          </WrapperRow>
        )}
      </List>
    )}
  </AutoSizer>
)};

export default VirtualList