import Cookies from 'js-cookie';

export const getCookie = (...inputs) => {
  const cookie_name = inputs[0]
  const cookie_value = Cookies.get(cookie_name);
  // console.log("getCookie", cookie_name, Cookies.get());
  return cookie_value
}

export const setCookie = (...inputs) => {
  const cookie_name = inputs[0]
  const cookie_value = inputs[1]
  const httpOnly = inputs[2]
  const path = inputs[3]
  const expires = inputs[4]

  let options = { httpOnly: httpOnly, path: path };
  if (expires) {
    options.expires = expires;
  }
  Cookies.set(cookie_name, cookie_value, options);
  let value = Cookies.get(cookie_name)
  // console.log("setCookie", cookie_name, cookie_value, options, value, Cookies.get());
  return cookie_value;
}