export const emit = (...inputs) => {
  const event = inputs[0]
  const event_inputs = inputs[1]
  let target = null
  if(inputs.length >= 6){
    target = inputs[2]
  }
  const eventctl = inputs[inputs.length-1]
  //console.log("emit", event, event_inputs, states, inputs, eventctl);
  if (target == null){
    return eventctl.emit(event, event_inputs);
  }else{
    return eventctl.emit(event, event_inputs, target);
  }
}

export const add_onload = (...inputs) => {
  const id = inputs[0]
  const onload_func = inputs[1]
  const func_inputs = inputs[2]
  
  const eventctl = inputs[inputs.length-1]
  //console.log("emit", event, event_inputs, states, inputs, eventctl);
  eventctl.add_onload(id, onload_func, func_inputs);
}

export const add_onrender = (...inputs) => {
  const id = inputs[0]
  const onrender_func = inputs[1]
  const func_inputs = inputs[2]
  
  const eventctl = inputs[inputs.length-1]
  //console.log("emit", event, event_inputs, states, inputs, eventctl);
  eventctl.add_onrender(id, onrender_func, func_inputs);
}

export const remove_onload = (...inputs) => {
  const id = inputs[0]
  
  const eventctl = inputs[inputs.length-1]
  //console.log("emit", event, event_inputs, states, inputs, eventctl);
  eventctl.remove_onload(id);
}

export const remove_onrender = (...inputs) => {
  const id = inputs[0]
  
  const eventctl = inputs[inputs.length-1]
  //console.log("emit", event, event_inputs, states, inputs, eventctl);
  eventctl.remove_onrender(id);
}

import _throttle from 'lodash/throttle';

export const throttle = (...inputs) => {
  const func = inputs[0]
  const wait = inputs[1]
  const options = inputs[2]
  
  return _throttle(func, wait, options)
}