import React, { Lazy } from 'react';


export default function Dropdown(props) {
  const { options } = props;
  
  const options_rendered = Object.entries(options).map(([key, option])=>{
    return (<option {...option.props}>{option.props.text}</option>)
  })
  
  return (
    <select {...props}>
      {options_rendered}
    </select>
  );
}
