import React, { Lazy } from 'react';


export default function SvgPath(props) {
  
  return (
    <path {...props}>
    </path>
  );
}
