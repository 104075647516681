import React, { Lazy } from 'react';


export default function SvgCircle(props) {
  
  return (
    <circle {...props}>
    </circle>
  );
}
